@font-face {
	font-family: 'ElegantIcons';
	src:url('fonts/ElegantIcons.eot');
	src:url('fonts/ElegantIcons.eot?#iefix') format('embedded-opentype'),
		url('fonts/ElegantIcons.woff') format('woff'),
		url('fonts/ElegantIcons.ttf') format('truetype'),
		url('fonts/ElegantIcons.svg#ElegantIcons') format('svg');
	font-weight: normal;
	font-style: normal;
}

.elegant_icon {
	font-family: 'ElegantIcons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}