canvas.pg-canvas {
	position: fixed;
	top: 0;
	z-index: 0;
}	

	// #page_overlay {
	// 	width: 100%;
	// 	height: 100%;
	// 	background: $light_color;
	// 	position: fixed;
	// 	left: -2000px;
	// 	opacity: 0;
	// 	z-index: 19;
	// 	@include transition;
	// }

.videoWrapper {        
     position: relative;        
     padding-bottom: 56.25%; /* 16:9 */        
     height: 0;

}

.videoWrapper iframe {        
     position: absolute;        
     top: 0;        
     left: 0;        
     width: 100%;        
     height: 100%;
}	

#page {

	// background: $dark_grey;
	overflow-x: hidden;
	@include transition;
	
	.page_bg {
		width: 55%;
		height: 100%; //gets overwriten by jQuery(?)
		// min-height: 800px;
		background-size: cover!important;
		background-position: 50% 50%!important;
		// -webkit-filter: saturate(0%);		
		// filter: saturate(0%);			
		opacity: 0;
		position: fixed;	
		left: 0;
		z-index: 15;

		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			display: block;
			background: $medium_color;
			opacity: 0.5;
		}
	}

	#primary {
		height: 100%; //gets overwriten by jQuery
		// min-height: 800px;		
		right: 0;
		top: 0;
		width: 100%;
		padding-left: 55%;
		overflow: auto;
		position: relative;
		background: $light_color;
		overflow-x: hidden;

		&:before {
			// content: "";
			width: 55%;
			height: 100%;
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			background: $medium_color; /* Old browsers */


		}		

		.comments-title {
			font-size: 40px!important;
		}

		.comment-reply-title {
			font-size: 30px!important;
		}

		.comment-form-comment label {
			font-family: $font__sec;
			font-size: 18px;
			font-weight: 900;
			text-transform: uppercase;
			letter-spacing: 0em;
		}

		textarea#comment {
			background: $light_color;
			border: 1px solid $medium_color;
			color: $dark_color;
			padding: 10px;
		}			

		#main {
			height: 100%;
			max-width: 800px;
			overflow-x: hidden;
			padding: 30px;
			// padding-top: 250px;
			position: relative;
			z-index: 10;

			.page-header {
				// padding-bottom: 30px;
				// text-align: center;
			}		
		}
	}	

	#secondary {
		padding: 30px 50px 5px $menu_width + 50px!important;
		background: $light_color url(images/footer_bg.png);
		background-size: 100% 100%;
		position: relative;
		z-index: 20;
		border-bottom: 10px solid $dark_color;
		// box-shadow: 0 0 10px 0px black;
		// border-top: 5px solid rgba(0,0,0,0.2);

		&:before {
			content: "";
			width: 100%;
			height: 5px;
			top: -5px;
			left: 0;
			position: absolute;
			display: block;
			background: $dark_grey;
			opacity: 0.3;
		}
	}

	.box {
		background: $light_grey;
		border-top: 1px solid #ccc;
		padding: 20px;
		margin: 30px 0;

		li {
			margin-bottom: 10px;
		}
	}

	.entry-footer {
		margin-top: 20px;

		.post-nav {
			// background: $light_grey; 
			border: 1px solid #ccc;
			padding: 20px 0;
			margin-bottom: -10px;
			// @include transition;

			// &:hover {
			// 	background: $light_grey;
			// 	@include transition;
			// }	
		
			div {
				text-align: right;
				position: relative;
				right: 70px
			}

			span {
				font-weight: 300;
			}

			i {
				position: absolute;
				right: -50px;
				top: -3px;
			}
		}

	}

	.team-member {
		clear: both;
		border-top: 1px solid #ccc;
		display: inline-block;
		margin-top: 50px;

		&:first-child {
			margin-top: 20px;
		}

		.info {
			float: left;
			width: 30%;
			text-align: right;

			img {
				height:100px; 
				margin-top: -20px;
				float: right;
			}

			h3 {
				padding-top: 10px;
				// padding-bottom: 5px;
				margin-bottom: $baseline - 20px;
			}

			h6 {
				line-height: $baseline;
			}
			
		}

		.bio {
			float:left; 
			width: 70%; 
			padding-left: 30px; 
			padding-top: 10px; 
		}

		.box {
			margin: 0;
		}		
	}

	.separator {
		position: relative;
		width: 50px;
		height: 5px;
		display: block;
		margin-top: $baseline * 2;
		margin-bottom: $baseline;
		background: $accent_color;		
	}

	.testimonial-wrapper {

		.testimonial {
			background: $light_grey;
			border-radius: 30px;
			text-align: center;
			margin: 20px 0 50px 0;
			padding: 10px 30px 10px 30px;
			border: 2px solid $light_grey;
			border-left: 0;
			border-right: 0;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				bottom: -40px;
				left: 80px;
				border-width: 40px 40px 0 0 ;
				border-style: solid;
				border-color: $light_grey transparent;
				display: block;
				width: 0;				
			}
		}	

		span {
			font-family: $font__sec;
			font-style: italic;
			font-size: 14px;
			display: block;
			margin-left: 40px;
			margin-bottom: 40px;
			line-height: 1.2em;

			strong {
				font-size: 16px!important;
				display: block;
				line-height: 1em;
				margin-bottom: 5px;
			}
		}	
	}	

	.tabs {
	    float: none;
	    list-style: none;
	    padding: 0;
	    margin-top: 0;
	    // border-bottom: 4px solid #ccc;
	}
	 
	.tabs:after {
	    content: '';
	    display: table;
	    clear: both;
	}
	 
	// .tabs input[type=radio] {
	//     display:none;
	// }
	 
	.tabs li {
	    display: block;
	    float: left;
	    width: 40%;
	 
	    color: $dark_grey;
	    font-size: 20px;
	    text-transform: uppercase;
	    font-weight: normal;
	    text-decoration: none;
	    text-align: center;
	    padding: 10px 0 5px 0 ;
	 
	    cursor: pointer;
	    border-top: 2px solid $dark_grey;
	    border-bottom: 2px solid $dark_grey;
	 
	    -webkit-transition: all 0.5s; /* Safari 3.1 to 6.0 */
	    transition: all 0.5s;

		&:first-of-type {
		    width: 20%;
		}

		a {
			color: $dark_grey;

			&.active {
				color: $accent_color;
			}

			&:hover {
				color: $dark_color;
			}			
		}	
	}
	  
	.tabs label span {
	    // display: none;
	}
	 
	.tabs label i {
	    padding: 5px;
	    margin-right: 0;
	}
	 
	.tabs label:hover {
	    color: $dark_color;
	    // box-shadow: inset 0 2px $dark_color;
	    // border-bottom: 2px solid $dark_color;
	}
	 
	.tab-content {
	    // display: none;
	    width: 100%;
	    float: left;
	    padding: 15px 0;
	    box-sizing: border-box;
	    -webkit-animation: enter 0.7s ease-in-out;
	    -moz-animation: enter 0.7s ease-in-out;
	    animation: enter 0.7s ease-in-out;
	}
	 
	@keyframes enter {
	 
	  0% { 
	    transform: opacity(0);
	    // right: -50%;
	    opacity: 0;
	    }
	  
	  100% { 
	    transform: opacity(1);
	    // right: 0;
	  }
	}	

	.case-study-number {
		font-family: $font__sec;
		text-transform: uppercase;
		font-weight: 900;
		margin-bottom: 3px;
		font-size: 16px;
	}

}

.blog, .page-template-template-blog {

	// img {
	// 	width: 40%;
	// 	float: left;			
	// }

	.image {
		width: 40%;
		height: 150px;
		float: left;
		background-size: cover!important;
		background-position: 50% 50%!important;			
	}

	.content {
		display: block;
		width: 60%;
  		float: left;
  		margin-bottom: 40px;
  		padding-left: 20px;

		.post-category {
			font-family: $font__sec;
			font-weight: 900;
			text-transform: uppercase;
			@include transition;
			font-size: 14px;
			line-height: 1.5em;
			margin-bottom: 3px;
			letter-spacing: 0.1em;
			
			a {
				color: $dark_color;
			
				&:hover {
					color: $accent_color;
				}
			} 
		}

		h4:hover {
			color: $medium_color;
		}

		h4 {
			margin-bottom: 0;
			color: $accent_color;
			@include transition;
		}

		a:hover h4 {
			color: $dark_color;
			@include transition;
		}

		p {
			color: $dark_color;
			margin-bottom: 0;
			margin-top: 5px;
			@include transition;
		}

		.post-categories {
			margin: 0;
			padding: 0;
		}

		.post-categories li {
			list-style: none;
		}

		.continue_reading {
			margin-top: 5px;
			display: block;
		}
	}
}

.archive {
	@extend .blog;
}

.page-template-template-case-studies {
	@extend .blog;

	.content h4 {
		color: $accent_color;
	}

	.tabs {

		.tab-content {
			margin-top: 40px;
			padding: 0!important;
		}
	}
}

.post-nav {
	text-align: center;
	font-family: $font__sec;
	font-weight: 900;
	font-size: 0.9em;
	line-height: 1.3em;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	margin-bottom: 60px; 

	i {
		font-size: 2.8em;
		position: relative;
		top: 10px;
	}
}

.single {

	.entry-header h6 {
		font-family: $font__sec;
		font-weight: 900;
		text-transform: uppercase;
		@include transition;
		// font-size: 14px;
		letter-spacing: 0.1em; 	
		margin-bottom: 5px;	
	}

	.entry-meta {
		font-weight: 600;
		font-family: $font__sec;
		text-transform: uppercase;	
		border-top: 1px solid $dark_grey;
		border-bottom: 1px solid $dark_grey;
		padding: 5px 0;
		line-height: 1.2em;		
	}	
}

.single-case_studies {

	.case_study_section {
		position: relative;
		margin-top: 30px;

		&:before {
			content: "";
			position: relative;
			width: 50px;
			height: 5px;
			display: block;
			margin-bottom: $baseline / 2;
			background: $accent_color;			
		}
	}

}

.contact-info {
	width: 50%; 
	float: left; 
	padding-right: 20px;

	p {
		margin-bottom: 0;
	}
}


.widget-area {

	a {
		color: $dark_color;
		text-decoration: underline;

		&:hover {
			color: $accent_color;
		}	
	
		&:hover i {
			background: $accent_color;
			color: $dark_color;
			@include transition;
		}	
	}

	i.elegant_icon {
		font-size: 15px;
		padding: 15px;
		margin: 0 5px 5px 0;
		// border: 2px solid;
		background: $light_color;
		color: $accent_color;
		display: inline-block;
		@include transition;
	}
}

.site-footer {
	// background: $medium_color;
	// padding-left: $menu_width;
	// color: $dark_grey;
	// position: relative;
	// z-index:11;

	.site-info {
		text-align: center;
		padding: 8px 20px;
		font-size: 11px;
	}

	a {
		color: $light_color;
		
		&:hover {
			color: $dark_grey;
		}

	}
}

@media screen and (max-width: $first_break) {

	#page {

		#primary {
			padding-left: 40%;
		}

		.page_bg {
			width: 40%;
		}

		#secondary {
			padding: 30px 30px 30px $menu_width!important;			
		}			

	}		

}

@media screen and (max-width: $second_break) {

	#page {

		.page_bg, #primary {
			width: 100%;
			// position: relative;
			float: none; 
		}

		#primary {
			// height: auto!important;
			// padding-top: 260px;
			padding-left: $menu_width - 20px;	
			padding-top: 250px;		
			overflow: hidden;
			// padding-left: $menu_width;
		}

		.page_bg {
			// display: none;
			height: 250px!important;
			top: 0px!important;
			bottom: auto!important;
			min-height: initial;
			position: absolute!important;
			// padding-left: $menu_width;
			box-shadow: none;
		}

		#secondary {
			text-align: center; 
		}

		.widget {
			width: 100%;
			max-width: 400px;
			text-align: center;
		}

		.fadeInLeft, .fadeInRight {
			opacity: 1!important;
			-webkit-transform: translateX(0px)!important;
	    	transform: translateX(0px)!important;
	    	animation-name: none!important;	
		}					
	
		.team-member .info {
			width: 100%;
			text-align: left;
			// padding-left: 200px;
		
			img {
				position: relative;
				margin-left: 10px;
				float: left;
			}		

		}

		.team-member .bio {
			width: 100%;
			padding-left: 0;
			padding-top: 0;
		}

	}	
} 

@media screen and (max-width: $third_break) {

	#page {
		padding-left: 0px;

		#primary {
			padding-left: 0px;
			// padding-top: 250px;

			#main {
				padding: 30px 40px;
			}		
		}

		#secondary {
			padding: 30px 30px!important;
		}	
	
		.tabs li {
			width: 100%;
			border-top: 0;

			&:first-of-type {
				width: 100%;
				border-top: 2px solid $dark_grey;
			}
		}
	}
} 

@media screen and (max-width: $fourth_break) {

	#page #primary #main {
		padding: 20px
	}

	.post-nav {
		font-size: 0.8em;
	}

	.blog .image, .archive .image, .page-template-template-case-studies .image, .page-template-template-blog .image {
		width: 100%;
		margin-bottom: 5px;
	}

	.blog .content, .archive .content, .page-template-template-case-studies .content, .page-template-template-blog .content {
		width: 100%;
		padding-left: 0;
	}

	.blog .content .post-category, .archive .content .post-category, .page-template-template-case-studies .content .post-category, .page-template-template-blog .content .post-category {
		font-size: 12px;
	}

	.single .entry-header h6 {
		font-size: 14px;
		line-height: 1.2em;
	}

	.contact-info {
		width: 100%;
		padding: 0;
	}

}