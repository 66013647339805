.widget {
	margin: 0 2% 20px 2%;
	width: 29%;
	// float: left;
	display: inline-block;
	vertical-align: top;

	/* Make sure select elements fit in widgets. */
	select {
		max-width: 100%;
	}
}

/* Search widget. */
.widget_search .search-submit {
	display: none;
}