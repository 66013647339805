a {
	color: $accent_color;
	text-decoration: none;
	@include transition;

	&:visited {
		// color: $accent_color;		
	}
	&:hover {
		color: $dark_color;
		@include transition;		
	}
	&:focus {
		outline: 0;
	}
	&:hover,
	&:active {
		outline: 0;
	}
}