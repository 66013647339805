.main-navigation {
	clear: both;
	display: block;
	float: left;
	width: $menu_width;
	// height: 100%;
	// min-height: 700px;
	position: fixed;
	top: 0;
	bottom: 0;
	// left: -300px;
	// background: $dark-grey;
	// box-shadow: 0 0 15px 5px $dark_color;
	z-index: 30;
	overflow: visible;

	.menu_container {
		width: 100%;
		height: 100%;
		min-height: 800px;
		background: rgba(255,255,255,1) url(images/menu_bg.png);
		background-size: 100% 100%;
		// background-position: top right;	
		text-align: center;
		padding-top: 30px;
		// padding-right: 20px;
		// box-shadow: 0 0 20px 0 #000;
	}

	.logo img {
		width: 70%;
		margin-bottom: 40px;
		// margin-left: -30px;
		float: none;
	}

	.elegant_icon {
		font-size: 35px;
		display: block;
		margin: 10px 0 10px 0;
		position: relative;
		top: 4px;
		color: $light_color;
		opacity: 0.8;
		@include transition;		
	}

	ul {
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;
		margin-bottom: 100px;

		ul {
			// box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
			float: left;
			position: absolute;
			top: 0em;
			left: $menu_width;
			z-index: 99999;
			visibility: hidden;
			width: $menu_width;
			text-align: left;
			margin-bottom: 0px;			

			li {
				text-align: left;
				width: 100%;
				display: inline-block;
				padding: 2px 0; 
				opacity: 0;
				left: 0px;
				@include transition;
			

				&:hover > ul,
				&.focus > ul {
					left: 100%;
				}
			}

			ul {
				// left: -999em;
				// visibility: visible;
				top: 0;
 				position: relative;
  				left: 20px!important;

  				a {
  					background: $accent_color;
  					font-weight: 300;
  				}

  				li:hover a {
  					color: $dark_color!important;
  				}

			}

			a {
				display: inline-block;
				width: auto;
				background: $dark_color;
				padding: 7px 10px;
				margin-left: 0px;
				color: $light_color!important;
				line-height: 1.5em;
			}

			:hover > a,
			.focus > a {
				color: $accent_color!important 
				// color: $accent_color!important;
			}

			a:hover,
			a.focus {
			}
		}

		li a {
			color: $light_color;
			// padding-right: 20px;
		}

		li:hover > ul,
		li:hover > ul ul,
		li.focus > ul
		li.focus > ul ul {
			// left: $menu_width;
			visibility: visible;
		}

		li:hover > ul li,
		li.focus > ul li {
			opacity: 1;
			left: 10px;
			@include transition;
		}

	}
	li {
		float: none;
		position: relative;
		// padding: 5px 0;
		text-align: center;
		// margin-left: 30px;
		// border-bottom: 1px solid $accent_color;
		opacity: 0;
		white-space: normal;

		a {
			color: $light_color;
		}

		&:hover > a,
		&.focus > a,
		&:hover > a i, {
			color: $light_color;
			@include transition;
		}
	}
	a {
		display: inline-block;
		text-decoration: none;
		color: $light_color;
		font-size: 10px;
		text-transform: uppercase;
		font-family: $font__sec;
		font-weight: 900;
		letter-spacing: 0.3em;
		// background: $dark-grey;
		margin-bottom: 2px;
		padding: 4px 10px;

	}

	li:hover a,
	li:hover a i,
	.current_page_item > a,
	.current-menu-item > a,
	.current_page_ancestor > a,
	.current_page_item > a i,
	.current-menu-item > a i,
	.current_page_ancestor > a i 
	{
		color: $dark_color;
	}

}

// exception for icons on contact page menu
.page-id-27 .current_page_ancestor > a,
.page-id-27 .current_page_ancestor > a i {
	color: $light_color;
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
	display: block;	
}

.mobile_header {
	display: none;
	// position: fixed;
	z-index: 100;
	position: relative;
	top: 0;
	background: $light_color url(images/mobile_header_bg.png);
	background-size: 100% 100%;
	width: 100%;
	height: 70px;
	// box-shadow: 0 0 20px 0 #000; 
	text-align: left;	

	.logo-mobile {
		// height: 100%;
		// padding: 10px;
		// position: absolute;
		// top: 5px;
		// left: 20px;
		// color: #fff;
		// background: $light_color;
		// border-radius: 50%;

		img {
			height: 100%;
			padding: 12px 0;
			margin-left: 20px;
		}

	}

	// ul.menu-tabs {
	// 	position: relative;
	// 	top: 80px;
	// 	font-family: $font__sec;
	// 	font-size: 10px;
	// 	letter-spacing: 0.2em;
	// 	font-weight: 900;
	// 	text-transform: uppercase;
	// 	color: $light_color;
	// 	padding: 0;

	// 	li {
	// 		display: inline-block;
	// 		padding: 10px 5px; 
	// 		background: $accent_color;
	// 	}
	// }	
}

.open-menu-mobile {
	display: none;
	position: absolute;
	top: 20px;
	right: 20px;
	// text-align: center;
	letter-spacing: 0;
	// width: 50px;
	// height: 50px;
	// height: 100%;
	// background: $light-color url(images/menu_bg.png);
	// filter: grayscale(100%);
	// -webkit-filter: grayscale(100%);
	// box-shadow: 0 0 20px 0 #000;
	z-index: 50;
	cursor: pointer; 

	i {
		font-size: 40px;
		color: $light_color;
	}
}

body #responsive-menu .responsive-menu {
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 900;
	letter-spacing: 0.3em;

	li a {
		text-align: center;
	}

	li li a {
		padding: 0px!important;
		font-weight: 300;
	}

}


@media screen and (min-width: 0em) {
	.menu-toggle {
		display: none;
	}
	.main-navigation ul {
		display: block;
	}
}

.comment-navigation,
.posts-navigation,
.post-navigation {

	.site-main & {
		margin: 0 0 1.5em;
		overflow: hidden;
	}

	& .nav-previous {
		float: left;
		width: 50%;
	}

	& .nav-next {
		float: right;
		text-align: right;
		width: 50%;
	}
}

@media screen and (max-width: $first_break) {

	.main-navigation {
		width: $menu_width - 20px;

		.logo img {
			width: 70%;
		}

		ul ul {
			left: $menu_width - 20px; 
		}

	}
}

@media screen and (max-width: $third_break) {

	.mobile_header, .open-menu-mobile {
		display: block;
		position: fixed;
	}

	.main-navigation {
		// left: -100%;
		opacity: 0;
		z-index: 0;
		width: 100%;
		position: fixed;
		height: auto;
		overflow: hidden;
		overflow-y: auto; 
		@include transition;

		.menu_container {
			height: auto;
			min-height: 100%!important;
			opacity: 1;
			background: $light_color;
			// background: rgba(255,255,255,1) url(images/footer_bg.png);
			// background-size: cover;

			padding-right: 0;
			padding-top: 100px;
			// padding-left: 20px;
			-webkit-transform: translateX(-0px);
	    	transform: translateX(-0px);	

		}

		.logo {
			display: none;
		}

		ul.menu {
			padding-left: 0px;
			height: auto;
			margin: 0;
			padding-bottom: 50px;

			li {
				margin: 0;
				display: inline-block;
				width: 100%;
				padding: 0 50px;
				-webkit-transform: translateX(0px);
				transform: translateX(0px);				

				a {
					padding-bottom: 3px;
					color: $accent_color;

					i {
						color: $accent_color!important;
					}					
				}

				&:hover > ul li, &:focus > ul li {
					left: 0;
				}
			}

			ul.sub-menu {
				// border-top: 1px solid white;
				width: 100%;
				visibility: visible;
				padding-left: 0px;
				left: 0;
				position: relative;
				// padding-top: 5px;
				// max-height: 0;
				// overflow: hidden;
				@include transition(0.5s);

				&.active {
					max-height: 400px;
					@include transition(0.5s);
				}

				li {
					width: 100%;
					opacity: 1;
					padding: 5px;
					text-align: center;
					display: block;
					margin-bottom: 0px;
					// border-bottom: 1px solid #ccc;

					a {
						background: none;
						font-weight: 300;
						padding: 0;
						display: block;
						color: $dark_color!important;

					}

				}
			}

		}

		li {
			opacity: 1;
		}
	}

	&.menu-mobile {
		z-index: 40;
		opacity: 1;
		@include transition;
		transition-delay: 0.5;
	}	

} 
