table {
	margin: 0 0 $baseline;
	width: 100%;
}

table.schedule {

	tr {
		border-bottom: 1px solid $dark-grey;
	}
}