ul, ol {
	// margin: 0 0 1.5em 3em;
}

ul {
	// list-style: none;
	margin-bottom: $baseline;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5em 1.5em;
}

ul.alert-list, ul.check-list {
	list-style: none;	
}

ul.alert-list li, ul.check-list li {

	margin-bottom: 0.5em;
	position: relative;	
	// border-bottom: 1px solid #eee;

	&:before {
		font-family: 'ElegantIcons';
		position: absolute;
		left: -25px;
		top: 1px;
		// font-size: 20px;
		color: $accent-color;
	}
}	

ul.alert-list li:before {
	content: '\72';
}

ul.check-list li:before {
	content: '\52';
}