input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
	color: $dark_color;
	border: none;
	border-bottom: 1px solid $dark_grey;
	background: $light_grey;
	padding: 10px;
	font-size: 0.9rem;
  	// line-height: 1;
  	margin-bottom: 20px;
  	width: 100%;

	&:focus {
		outline: none;
		background: $light-color;
	}
}

#secondary input[type="text"] {
	border: 1px solid $light-grey;
	background: none;
}

// input[type="text"],
// input[type="email"],
// input[type="url"],
// input[type="password"],
// input[type="search"] {
// 	padding: 3px;
// }

textarea {
	width: 100%;
}

#searchform input[type="text"] {
	width: 100%;
}

.wpcf7 {

	p {
		font-family: $font__sec;
		text-transform: uppercase;
		font-weight: 900!important;
		margin: 0;
	}
}