h1, h2, h3, h4, h5, h6 {
	clear: both;
	font-weight: 100;
	color: $dark_color;
	line-height: $baseline;
	margin: 0 0 $baseline 0;
}

h1, h2 {
	font-family: $font__sec;
	font-weight: 900;
	text-transform: uppercase;
}

h1 {
	font-size: 60px;
	line-height: $baseline * 2.2;
}

h2 {
	font-size: 40px;
	line-height: $baseline * 1.8;
}

h3 {
	font-size: 28px;
	line-height: $baseline * 1.4;
}

h4 {
	font-size: 24px;
	line-height: $baseline * 1.3;
}

h5 {
	font-size: 20px;
	line-height: $baseline * 1.1;
}

h6 {
	font-size: 18px;
	line-height: $baseline * 1.1;
}


@media screen and (max-width: $third_break) {

	h1 {
		font-size: 46px;
		line-height: $baseline * 1.6;
	}

	h2 {
		font-size: 34px;
		line-height: $baseline * 1.4;
	}

	h3 {
		font-size: 24px;
		line-height: $baseline * 1.2;
	}

	h4 {
		font-size: 20px;
		line-height: $baseline * 1;
	}

	h5 {
		font-size: 18px;
		line-height: $baseline;
	}

	h6 {
		font-size: 16px;
		line-height: $baseline - 3px;
	}

}

@media screen and (max-width: $fourth_break) {

	h1 {
		font-size: 38px;
		line-height: $baseline * 1.6;
	}

	h2 {
		font-size: 32px;
		line-height: $baseline * 1.4;
	}

	h3 {
		font-size: 23px;
		line-height: $baseline * 1.2;
	}

	h4 {
		font-size: 22px;
		line-height: $baseline * 1.2;
	}

	h5 {
		font-size: 18px;
		line-height: $baseline;
	}

	h6 {
		font-size: 16px;
		line-height: $baseline - 3px;
	}	

}

