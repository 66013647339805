$color__background-body: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: royalblue;
$color__link-visited: purple;
$color__link-hover: midnightblue;
$color__text-main: #404040;

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;


$light_color: #fff;
$medium_color: #66C3EA;
$dark_color: #404040;
$accent_color: #00acec;
$dark_grey: #ccc; 
$light_grey: #eee; 

