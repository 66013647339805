.home {

	.home_section_top {
		width: 100%;
		height: 100vh;
		display: table;
		// background: url('images/chess_bg.jpg');
		background-color: $accent_color; 
		background-size: cover;	
		text-align: center;
		padding: 0px 0px 0px 0px;
		position: relative;
		z-index: 1;
		// background: yellow;

		.video_container {
		    position:absolute;
		    height:100%;
		    width:100%;
		    overflow: hidden;
		    opacity: 0.7;
		    // filter: blur(3px) contrast(120%);
		    // -webkit-filter: blur(3px) contrast(120%);
		}

		.video_container video {
		    min-width: 100%;
		    min-height: 100%;
		    // -webkit-filter: saturate(0%) contrast(100%);
		}		

		.center_vert {
			display: table-cell;
			vertical-align: middle;
			padding: 20px 20px 20px $menu_width;
		}		
	
		h1 {
			// font-size: 4.5em;
			margin: 0 auto;
			width: 830px;
			color: $accent_color;
		}

		.intro {
			max-width: 800px;
			margin: 0 auto;
			margin-top: 20px;
		}

		button {
			margin-top: 30px;
		}

	}

	// .home_section {
	// 	width: 100%;
	// 	padding: 50px 50px 50px $menu_width + 50px;

	#page #primary {
		height: 100%!important;
		overflow: hidden;
		padding-left: 20px;
		width: auto!important;
		position: relative!important;
		border-bottom: none!important;

		#main {
			padding: 50px 50px 50px ($menu_width + 30px)!important;
			position: relative;
			z-index: 5;
			max-width: 1600px;
			margin: 0 auto;			 
		}

		.box_left, .box-right {
			width: $box_width;
			height: $box_width - 50px;
			background: $accent_color;
			padding: 40px;
			z-index: 10;
			position: relative;

			h2 {
				color: $light_color;
				line-height: 1.1em;
				font-size: 46px;
			}
		}

		.box_left {
			// float: left;
			width: 35%;
			margin-left: 40px;
			// margin-right: 5%;
			display: inline-block;
			box-sizing: border-box;
		}

		.box_right {
			// float: right;
			// margin-left: 40px;		
		}

		.box_light {
			background: $light_color;

			h2 {
				color: $accent_color;
			}
		}

		.next_to_box {
			// overflow: hidden;
			padding-top: 40px;
			padding-left: 5%;
			display: inline-block;
			width: 60%; 
			vertical-align: top;
			box-sizing: border-box;
		}

		.image_training {
			width: 100%;
			height: 300px;
			background: url(images/home_pic.jpg);
			background-size: cover;
			background-position: 50% 50%;
			margin-top: -100px;
			position: relative;
			z-index: -1;
			// right: -70px;
			// -webkit-transform: translate(-50px, 0);
			//    -moz-transform: translate(-50px, 0);
			//         transform: translate(-50px, 0);
			// -webkit-filter: contrast(80%);
		
			&:after {
				    content: "";
				    position: absolute;
				    width: 100%;
				    height: 100%;
				    display: block;
				    background: $dark_grey;
				    opacity: 0.2;
			}

		}
		.services_container {
			overflow: hidden;
			padding-top: 40px;
			margin-top: -40px;

			h3 {
				// margin-bottom: 0;
				line-height: 1em;
			}	
		}

		.half {
			width: 50%;
			float: left;
			padding: 0 20px 0 80px;
			box-sizing: border-box;
			margin-top: -20px;	
		}

		.icon_circle {
			background: $accent_color;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			// display: block;
			position: relative;
			top: -10px;
			left: -40px;
			text-align: center;
			display: table;
			box-shadow: 0 0 0 2px $light_color, 0 0 0 7px $accent_color;
			
			i {
				color: $light_color;
				font-size: 35px;
				display: table-cell;
				vertical-align: middle;
			}

		}

		.big_link {
			padding: 0 20px 50px;
			// margin: 0 0 50px 0; 
			font-family: $font__main;
			text-transform: lowercase;
			color: $accent_color;
			// background: #eee;
			@include transition;
			// border-top: 1px solid;
			// border: 1px solid #ccc;
			// width: 100%;
			// text-align: center;
		}

		.big_link:hover {
			// color: $dark_color;
			// background: $light_grey;
			// border-color: $accent_color; 
		}

		.home_testimonial {
			background: $light_grey;
			border-radius: 30px;
			text-align: center;
			margin: 100px 40px 120px 40px;
			padding: 30px 60px;
			// max-width: 500px;
			// max-width: 600px;
			// float: left;
			border: 2px solid $light_grey;
			border-left: 0;
			border-right: 0;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				bottom: -40px;
				left: 80px;
				border-width: 40px 0 0 40px;
				border-style: solid;
				border-color: $light_grey transparent;
				display: block;
				width: 0;				
			}
	
			h4 {
				// color: $accent_color;
				// font-size: 20px;
				max-width: 600px;
				margin: 0 auto;
				text-align: center;
				// line-height: $baseline + 10px;
			}

			span {
				font-family: $font__sec;
				font-style: italic;
				font-size: 14px;
				display: block;
				line-height: 1.2em;
				margin-top: 10px;
				font-weight: 300;

				strong {
					// font-size: 1.2em!important;
					// display: block;
					// line-height: 0.5em;
				}
			}

			.big_link {
				position: absolute;
				bottom: -110px;
				right: 50px;
				// margin-top: 20px;
				// margin-bottom: 0; 
			}
		}
		
		.image_when {
			width: 100%;
			height: 250px;
			background: url(images/meeting.jpg);
			background-size: cover;
			background-position: 50% 60%;
			margin: -40px 0 50px 0;
			position: relative;
			text-align: center;	
			// right: -50px;
			// -webkit-transform: translate(50px, 0);
			   // -moz-transform: translate(50px, 0);
			        // transform: translate(50px, 0);

			button {
				position: absolute;
				left: 40%;
  				top: 40%;
			}        
		}

		h6 {
			padding: 10px 0;
		}

		.image_when:after {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			// right: 0;
			background: transparent; 
			width: $box_width + 50px;
			height: 40px;		
		}

		.bx-wrapper .bxslider {
			padding: 0;
		}

		.bx-wrapper .bx-viewport {
			box-shadow: none;
			border: 0;
			margin: 10px 0;
			padding: 0 20px;
			background: transparent;
			// border-top: 1px solid $light_grey;
			// border-bottom: 1px solid $light_grey;
		}

		.bx-wrapper ul {
			height: 100%;
			margin-top: 0;
		}

		.bx-wrapper li {
			padding: 10px 80px;
			display: table;
			height: 100%;
		}

		.bx-wrapper li * {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
		}

		.bx-wrapper .bx-pager {
			bottom: -40px;
		}

		.bx-wrapper .bx-pager.bx-default-pager a {
			background: $medium_color;
		}

		.bx-wrapper .bx-pager.bx-default-pager a:hover, 
		.bx-wrapper .bx-pager.bx-default-pager a.active {
			background: $dark_grey;
		}

		.bx-wrapper .bx-next, .bx-wrapper .bx-prev {
			background: none;
			text-indent: 0;
			font-family: "ElegantIcons";
			font-size: 40px;
			color: $accent_color;
			top: 50%;
		} 

		.bx-wrapper .bx-next:hover, .bx-wrapper .bx-prev:hover {
			color: $dark_grey;
		}

		.case_studies_home_container {
  			position: relative;
  			width: 100%;
			z-index: 10;
			margin-top: -100px;
			padding-bottom: 20px;
			clear: both;
			display: inline-block;
			// border-bottom: 2px solid $light_grey;
		}

		.case_study_home {
			width: 33.333%;
			float: left;

			&:hover > .cs_thumb:before {
				// background: $light_color;
			}

			&:hover > .cs_thumb img {
				opacity: 0.5;
			}

			&:hover .cs_content {
				// top: 40%!important;
				opacity: 1!important;
			}

			&:hover > h6 {
				color: $dark_color;
			} 
 			
			.cs_thumb {
				width: 100%;
				height: 220px;
				border: 1px solid $light_color;
				background-size: cover!important;
				background-position: 50% 50%!important;
				position: relative;
				overflow: hidden;

				&.portrait img {
					// max-width: 100%;
					// max-height: none;					
					height: 150%;
					width: auto;
				}
				
				&.landscape img {
					// max-height: 100%;
					// max-width: none;					
					width: 150%;
					height: auto;
				}

				img {
					-webkit-filter: grayscale(0%); 
					filter: grayscale(0%);
					// height: 120%;
					width: auto;
					max-width: none;
					opacity: 0.8;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
					@include transition();
				}

				.cs_content {
					position: absolute;
					width: 100%;
					// height: 100%;
					// top: 20px;
					// left: 0;
					padding: 10px 20px;
					color: $light_color;
					opacity: 0;
					text-align: center;
					@include transition;
					top: 50%;
					transform: translateY(-50%);
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);					
				}					

				&:before {
					content: "";
					position: absolute;
					top: 0px;
					left: 0;
					width: 100%;
					height: 100%;
					background: $dark_grey;
					opacity: 1;
					border: 1px solid $light_color;
					@include transition();
				}			
			}

			.case_title {
				color: $accent_color;
				background: $light_color;
				margin: 5px 0px 0px 0px;
				padding: 5px 0 5px 20px;
				position: relative;
				z-index: 20;
				@include transition;
			}			

		}			

	}

}

@media screen and (max-width: $first_break) {

	.home .home_section_top .center_vert,
	.home #page #primary #main {
		padding: 50px 40px 50px $menu_width!important;
	}

	.home .home_section_top h1 {
		width: 90%;
		font-size: 60px;
		line-height: 1em;
	}

	.home .home_section_top .intro {
		max-width: 700px;
	}	

	.home #page #primary {
		width: 100%;
		float: none;
		background: none;
		height: auto;

		.box_left {
			width: 40%;
			// background: none;
			margin-left: 20px;
			// margin-right: 5%;
			// padding: 30px;
			height: 280px;
		}

		.box_left h2 {
			// color: $accent_color!important;
			// margin-bottom: 10px;
			// padding-bottom: 30px;
			// font-size: 50px;
			// max-width: 500px;			
		} 

		.next_to_box {
			width: 55%;
			// padding: 30px;

			h4 {
				font-size: 24px;
				line-height: 1.3em;
			}

			h5 {
				font-size: 20px;
				line-height: 1.3em;
			}			
		}

		.image_training {
			height: 270px;
			margin: -80px 0 0 0;
		}	

		.half {
			padding: 0 20px 0 20px;
		}

		.icon_circle {
			left: 0px;
		}

		.big_link {
			// font-size: 40px;
			float: right;
			// padding: 40px 0;
		}

		.when {
			display: none;
		}

		.image_when {
			margin: 10px 0 30px 0;
			// right: -50px;
		}

		.image_when:after {
			display: none;
		}

		.image_when button {
			left: auto;	
			margin: 0 auto;
			position: relative;
		}

		.home_testimonial {
			margin-top: 70px;
		}

		.case_studies_home_container	{
			margin-top: -80px!important; 
		}

		.case_study_home .cs_thumb {
			height: 180px;
		}

		.case_study_home .case_title {
			// font-size: 20px;
			padding-left: 5px;
		}

		.case_study_home .cs_content, .case_study_home .cs_thumb:before {
			display: none;
		}

		.case_study_home:hover .cs_thumb img {
			opacity: 0.8;
		}

	}	

}


@media screen and (max-width: $second_break) {

	.home .home_section_top .center_vert,
	.home #page #primary #main {
		padding-left: $menu_width + 10px!important;
		text-align: center;
	}

	.home .home_section_top {
		// height: auto!important;
		padding: 0px 20px 0px 0px;

		h1 {
			font-size: 60px;
			line-height: 1em;
		}

		.intro {
			// font-size: 1em;
			display: none;
		}
	}

	.home #page #primary {
		padding-top: 0;
		// text-align: center;

		.box_left {
			width: 100%;
			height: auto;
			margin-left: 0;
			background: none;
			padding: 0;

			h2 {
				// margin: 0 auto;
				margin: 0 auto 20px auto;
				color: $accent_color;			
				padding: 0;
				max-width: 500px;
				font-size: 54px;
				line-height: 1em;
			}
		}

		.next_to_box {
			width: 100%;
			padding: 0;
			// margin-bottom: 30px;

			h4 {
				font-size: 24px;
				padding: 0 10%;
			}
			
			h5 {
				padding: 0 10%;
			}

		}

		.image_training {
			margin: 0;
		}

		.half {
			width: 100%;
			// max-width: 500px;
			margin-top: 20px;
			float: none;
			margin-left: 0px;
			// border-left: 1px solid;
			// margin-top: 20px;
			padding: 10px 0px;
			position: relative;
			text-align: center;

			p {
				// margin: 5px 0 10px 0;
				// line-height: 1.5em;
			}
		}

		.icon_circle {
			position: absolute;
			left: -60px;
			top: 20px;
			display: none;
		}

		.services_container {

			.half {
				// width: 50%;
				// float: left;
				// text-align: left;
				// padding-right: 40px;
			}

			h2 {
				margin-bottom: 10px;
			}

			h5 {
				margin-bottom: 15px;
				padding: 0 20%;
			}

			p {
				display: none;
			}
		}			

		.big_link {
			float: none;
			bottom: -30px!important;
			right: auto!important;
			position: relative!important;
			padding: 0!important;
		}

		.image_when {
			margin-bottom: 50px;
		}

		.home_testimonial {
			padding: 30px;
			margin-top: 50px;
			margin-bottom: 100px;
		}

		.case_studies_home_container {
			margin-top: 0px!important;
		}		

		.case_study_home {
			width: 100%;
			// max-width: 400px;
			// margin: 0 auto;
			// float: left;
			display: table;
			margin-bottom: 5px;

			.cs_thumb {
				width: 50%;
				height: 100px;
				// float: left;
				margin-bottom: 10px;
				display: table-cell;
			}

			.case_title {
				width: 50%;
				padding-left: 20px;
				// float: left;
				display: table-cell;
				vertical-align: middle;
				height: 100%;
				clear: none;
				text-align: left;
			}
		}
	}

	.home #primary #main {
		padding: 50px 20px!important;
	}

}

@media screen and (max-width: $third_break) {

	.home .home_section_top,
	.home #page #primary {
		padding: 0;
	}
	
	.home .home_section_top .center_vert, 
	.home #page #primary #main {
		padding: 30px 30px 50px 30px!important;
		text-align: center;
	}

	.home .home_section_top .center_vert {
		padding-top: 80px!important;
	}

	.home .home_section_top .intro {
		display: none;
	}

	.home #page #primary .image_when {
		padding: 50px;
	}	

	button {
		left: auto;
		margin: 2px auto;
		position: relative;
		font-size: 10px;

		&.big_btn {
			// font-size: 0.5em;
		}
	}

	.home .home_section_top h1 {
		font-size: 50px;
		line-height: 1em;
	}

	.home .home_section_top .intro {
		// font-size: 0.8em;
	}

	.home #page #primary {

		.box_left {

			h2 {
				font-size: 44px;
				line-height: 1em;
			}			
		}

		.next_to_box {

			h4 {
				font-size: 22px;
				padding: 0 5%;
			}
			
			h5 {
				padding: 0 5%;
			}

		}
	}


	.home #page #primary .services_container {

		h2 {
			// margin-bottom: 10px;
		}

		h5 {
			padding: 0 5%;
		}

		p {
			// display: none;
		}
	}

	.home #page #primary .home_testimonial {
		margin: 50px 10px 80px 10px;
		padding: 30px 40px;
	}	

	.home #page #primary .case_study_home .cs_thumb {
		width: 45%;
	}

	.home #page #primary .case_study_home .case_title {
		width: 55%;
	}	
}	

@media screen and (max-width: $fourth_break) {

	.home .home_section_top h1 {
		font-size: 40px;
		line-height: 1em;
	}

	.home #page #primary .image_training {
		height: 180px;
	}

}

