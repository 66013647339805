body {
	font-size: 15px;
	line-height: $baseline;
}

p {
	margin-bottom: $baseline;
	// line-height: 1.5em;
	font-weight: 100;
	// @include font-size(0.9);
}

dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	margin: 0 50px 1.5em 50px;
	padding-left: 1em;
	border-left: 5px solid $accent_color;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: $color__background-pre;
	font-family: $font__pre;
	@include font-size(0.9375);
	line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	font-family: $font__code;
	@include font-size(0.9375);
}

abbr, acronym {
	border-bottom: 1px dotted $color__border-abbr;
	cursor: help;
}

mark, ins {
	background: $color__background-ins;
	text-decoration: none;
}

big {
	font-size: 125%;
}


@media screen and (min-width: $first_break) {

	body {
		// font-size: 16px;
	}

} 

@media screen and (max-width: $first_break) {


} 

@media screen and (max-width: $second_break) {

	body {
		// font-size: 14px;
		// line-height: $baseline - 2px;
	}

} 

@media screen and (max-width: $third_break) {


} 
