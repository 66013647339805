.light_color {
	color: $light_color;
}

.medium_color {
	color: $medium_color;
}

.dark_color {
	color: $dark_color;
}

.accent_color {
	color: $accent_color;
}

.clearfix {
	clear: both;
}

.clearfix-50 {
	clear: both;
	height: 50px;
}

.right {
	float: right;
}

.left {
	float: left;
}