button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 2px solid;
	border-color: $dark_color;
	background: transparent;
	color: $dark_color;
	@include font-size(0.7);
	line-height: 1;
	padding: 9px 25px;
	text-transform: uppercase;
	font-family: $font__sec;
	letter-spacing: 0.3em;
	font-weight: 900;
	@include transition;		

	&:hover {
		border-color: $dark_color;
		color: $light_color;
		background-color: $dark-color;
		@include transition;			
	}

	&:active,
	&:focus {
		// border-color: $dark_color;
		// color: $light_color;
		// background-color: $dark-color;
		outline: 0;
	}
}

button.light,
input.light[type="button"],
input.light[type="reset"],
input.light[type="submit"] {
	border-color: $light_color;
	color: $light_color;
	position: relative;
	// z-index: 10;

	&:hover {
		border-color: $light_color;
		color: $accent_color;
		background-color: $light-color;
	}

	// &:active,
	// &:focus {
	// 	border-color: $light_color;
	// 	color: $accent_color;
	// 	background-color: $light-color;
	// }
}	

button.inv,
input.inv[type="button"],
input.inv[type="reset"],
input.inv[type="submit"] {
	border-color: $light_color;
	color: $accent_color;
	background-color: $light-color;	

	&:hover {
		border-color: $accent_color;
		color: $light_color;
		background-color: $accent_color;	
	}

	// &:active,
	// &:focus {
	// 	border-color: $accent_color;
	// 	color: $light_color;
	// 	background-color: $accent_color;
	// }		
}

button.big_btn {
	@include font-size(0.9);
	padding: 12px 35px;
}

@media screen and (max-width: $fourth_break) {

	button.big_btn {
		@include font-size(0.8);
		padding: 10px 30px;
	}

}